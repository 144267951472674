import type { AsVuexStore } from '@zento-lib/components/vuex';

export const KEY = 'compare';

export type IVSFCompare = AsVuexStore<
{
  loaded: boolean;
  items: any[];
},
'isEmpty' | 'isOnCompare' | 'isCompareLoaded',
'load' | 'addItem' | 'removeItem' | 'clear',
'compare/ADD' | 'compare/DEL' | 'compare/LOAD' | 'compare/SET_COMPARE_LOADED'
>;
